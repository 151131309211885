<template>
  <div v-if="props.error.statusCode == 404" class="error">
    <img src="~images/pages/public/404.png" alt="404">
    <div class="name">您访问的页面不存在，请检查路径...</div>
    <nuxt-link to="/" class="back">返回首页</nuxt-link>
  </div>
  <div v-else-if="props.error.statusCode == 500" class="error">
    <img src="~images/pages/public/500.png" alt="500">
    <div class="name">正在抢修bug中...</div>
    <nuxt-link to="/" class="back">返回首页</nuxt-link>
  </div>
  <div v-else-if="props.error.statusCode == 560" class="error">
    <img src="~images/pages/public/500.png" alt="560">
    <div class="name">请求次数频繁，请稍后...</div>
    <nuxt-link to="/" class="back">返回首页</nuxt-link>
  </div>
  <div v-else class="error">
    <img src="~images/pages/public/empty.png" alt="other">
    <div class="name">{{decodeURIComponent(props.error.statusMessage)}}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  error: {

  }
})
</script>

<style scoped lang="scss">
.error {
  display: flex;
  align-items: center;
  //justify-content: center;
  padding-top: 25vh;
  height: 75vh;
  flex-direction: column;
  > img {
    width: 300px;
    height: 200px;
  }
  .name {
    font-size: 20px;
    color: var(--secondary-text-color);
    margin-top: 20px;
  }
}
.back {
  margin-top: 30px;
  font-size: 18px;
  color: var(--main-blue-color);
  text-decoration: underline;
}
</style>